<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols='12'>
          <v-card-title class="mb-5">
            4. Организационные мероприятия по установлению противопожарного режима.
          </v-card-title>
        </v-col>
          <v-col cols="12">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step
                  :complete="e1 > 1"
                  step="1"
                  color="red"
                >
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 2"
                  step="2"
                  color="red"
                >
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 3"
                  step="3"
                  color="red"
                >
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 4"
                  step="4"
                  color="red"
                >
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 5"
                  step="5"
                  color="red"
                >
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 6"
                  step="6"
                  color="red"
                >
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 7"
                  step="7"
                  color="red"
                >
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step color="red" step="8">
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card
                    class="d-flex align-center justify-center mb-12"
                    height="400px"
                  >
                    <v-img
                      lazy-src="img/material/fireman.svg"
                      max-height="50%"
                      max-width="25%"
                      contain
                      src="img/material/fireman.svg"
                      >
                    </v-img>
                    <!-- <v-divider vertical></v-divider> -->
                  <v-card-title class="headline font-weight-light pa-10 text-wrap">
                    <p>Создание и организация работы пожарно-технических комиссий,<br> добровольных дружин и команд</p>
                  </v-card-title>
                  </v-card>

                  <v-btn
                    color="red"
                    dark
                    @click="e1 = 2"
                  >
                    Далее
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card
                    class="d-flex align-center justify-center mb-12"
                    height="400px"
                  >
                  <v-img
                    lazy-src="img/material/document.svg"
                    max-height="50%"
                    max-width="30%"
                    contain
                    src="img/material/document.svg"
                    >
                  </v-img>
                  <v-card-title class="headline font-weight-light pa-10">
                    Разработка и утверждение инструкций<br> (по пожарной безопасности), организация изучения инструкций работниками
                  </v-card-title>
                  </v-card>

                  <v-btn
                    color="red"
                    dark
                    @click="e1 = 3"
                  >
                    Далее
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-card
                    class="d-flex align-center justify-center mb-12"
                    height="400px"
                  >
                  <v-img
                    lazy-src="img/material/exit-2.svg"
                    max-height="50%"
                    max-width="30%"
                    contain
                    src="img/material/exit-2.svg"
                    >
                  </v-img>
                  <v-card-title class="headline font-weight-light pa-10">
                    Обеспечение территорий, зданий и помещений знаками ПБ, табличками с номером телефона и порядком вызова пожарной охраны
                  </v-card-title>
                  </v-card>

                  <v-btn
                    color="red"
                    dark
                    @click="e1 = 4"
                  >
                    Далее
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="4">
                  <v-card
                    class="d-flex align-center justify-center mb-12"
                    height="400px"
                  >
                  <v-img
                    lazy-src="img/material/hotel-2.svg"
                    max-height="50%"
                    max-width="30%"
                    contain
                    src="img/material/hotel-2.svg"
                    >
                  </v-img>
                  <v-card-title class="headline font-weight-light pa-10">
                    Определение категорий зданий и помещений по <br>взрывопожарной и пожарной опасности, установление<br> классов зон
                  </v-card-title>
                  </v-card>

                  <v-btn
                    color="red"
                    dark
                    @click="e1 = 5"
                  >
                    Далее
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="5">
                  <v-card
                    class="d-flex align-center justify-center mb-12"
                    height="400px"
                  >
                  <v-img
                    lazy-src="img/material/fire-shield.svg"
                    max-height="50%"
                    max-width="30%"
                    contain
                    src="img/material/fire-shield.svg"
                    >
                  </v-img>
                  <v-card-title class="headline font-weight-light pa-10">
                    Установка на предприятии противопожарного режима.<br> Противопожарный режим оформляется в виде приказа или <br>инструкции о мерах пожарной безопасности
                  </v-card-title>
                  </v-card>

                  <v-btn
                    color="red"
                    dark
                    @click="e1 = 6"
                  >
                    Далее
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="6">
                  <v-card
                    class="d-flex align-center justify-center mb-12"
                    height="400px"
                  >
                  <v-img
                    lazy-src="img/material/alarm.svg"
                    max-height="50%"
                    max-width="30%"
                    contain
                    src="img/material/alarm.svg"
                    >
                  </v-img>
                  <v-card-title class="headline font-weight-light pa-10">
                    Установка системы оповещения людей о пожаре,<br> ознакомление с ней сотрудников
                  </v-card-title>
                  </v-card>

                  <v-btn
                    color="red"
                    dark
                    @click="e1 = 7"
                  >
                    Далее
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="7">
                  <v-card
                    class="d-flex align-center justify-center mb-12"
                    height="400px"
                  >
                  <v-img
                    lazy-src="img/material/interior-design.svg"
                    max-height="50%"
                    max-width="30%"
                    contain
                    src="img/material/interior-design.svg"
                    >
                  </v-img>
                  <v-card-title class="headline font-weight-light pa-10">
                    Разрарботка схем эвакуации людей
                  </v-card-title>
                  </v-card>

                  <v-btn
                    color="red"
                    dark
                    @click="e1 = 8"
                  >
                    Далее
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="8">
                  <v-card
                    class="d-flex align-center justify-center mb-12"
                    height="400px"
                  >
                    <v-img
                      lazy-src="img/material/exit-2.svg"
                      max-height="50%"
                      max-width="30%"
                      contain
                      src="img/material/exit-2.svg"
                      >
                    </v-img>
                  <div>
                    <v-card-title class="headline font-weight-light pa-5">
                      Назначение ответственных за ПБ:
                    </v-card-title>
                    <v-card-text class="title font-weight-light pb-0">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      отдельных зданий, сооружений, участков и т.п.;
                    </v-card-text>
                    <v-card-text class="title font-weight-light pb-0">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      технологического и инженерного оборудования;
                    </v-card-text>
                    <v-card-text class="title font-weight-light pb-0">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      содержание и эксплуатацию имеющихся технических средств противопожарной защиты.
                    </v-card-text>
                    <v-card-title class="title font-weight-light">
                      Определение обязанностей должностных лиц по обеспечению ПБ
                    </v-card-title>
                    <v-dialog
                      v-model="dialog"
                      width="90%"
                    >
                    <template v-slot:activator="{ on, attrs }" class="block">
                      <v-btn color="red"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      block
                      >
                        Меры, которые определяют должностные лица
                      </v-btn>
                    </template>

                    <v-card class="noselect">
                      <!-- <v-card-title>
                        Меры, которые определяют должностные лица
                      </v-card-title> -->

                      <v-card-title>
                        Меры, определяемые должностными лицами в ходе установления противопожарного режима:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Место курения, применения открытого огня, бытовых нагревательных приборов;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Порядок проведения временных пожароопасных работ (в том числе и сварочных);
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Места для хранения и допустимое количество сырья, полуфабрикатов и готовой продукции;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Порядок уборки горячей пыли, отходов и очистки воздуховодов вентиляционных систем от горючих отложений;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Порядок хранения промасленной спецодежды и тряпья;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Порядок отключения от сети электрооборудования в случае пожара;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Порядок осмотра и закрытия помещений после окончания работы;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Порядок прохождения должностными лицами обучения и проверки по вопросам ПБ, а также проведение с работниками противопожарных инструктажей и занятий по ПТМ с назначением ответственных за их проведением;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Порядок организации эксплуатации, обслуживания, имеющихся
                        <v-tooltip color="red" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              color="red"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="title font-weight-light blue lighten-4"
                            >
                              технических средств противопожарной защиты;
                            </span>
                          </template>
                          <span>противопожарного водопровода, насосных станций, устанавок пожарной сигнализации, автоматического пожаротушения, дымоудаления, огнетушителей и т.п.</span>
                        </v-tooltip>

                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Порядок проведения планово-предупредительных ремонтов и осмотров электроустановок, отопительного, вентиляционного, технологического и другого инженерного оборудования;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Порядок сбора членов
                        <v-tooltip color="red" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              color="red"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="title font-weight-light blue lighten-4"
                            >
                              ДПД
                            </span>
                          </template>
                          <span>добровольная пожарная дружина</span>
                        </v-tooltip>
                        и ответственных должностных лиц, в случае возникновения пожара;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Действия работников при обнаружении пожара, вызова ночью, в выходные и праздничные дни;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Действия работников при обнаружении пожара;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        Правила проезда и стоянки транспортных средств.
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="dialog = false"
                        >
                          Закрыть
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  </div>

                  </v-card>

                  <v-btn
                    color="red"
                    @click="e1 = 1"
                    dark
                  >
                    Далее
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
          <v-col cols="12">
            <Hint/>
          </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  components: {
    Hint
  },
  data () {
    return {
      e1: 1,
      dialog: false
    }
  }
}
</script>
